import { create } from 'zustand';
import { FormData } from '#types/auth.types';
import {
  authUser,
  authLogin,
  authLogout,
  authResetPassword,
  authRestorePassword,
} from '../utils/api/auth';
import { LocalStorageService, ACCESS_TOKEN_KEY } from 'src/utils/localstorage';

type UserInfoType = {
  first_name: string;
  permissions: string[];
  is_client: boolean;
  client_startup_popup: boolean;
  updates_popup_required: boolean;
};

interface IAuthStore {
  access_token: string | null;
  user: UserInfoType | null;
  isLoading: boolean;
  isUserLoading: boolean;
  error: string | null;
  isSuccess: boolean;

  actions: {
    fetchUser: () => Promise<void>;
    readStartupPopup: () => void;
    readUpdatesPopup: () => void;
    signIn: (form: FormData, rememberMe: boolean) => Promise<void>;
    logout: () => void;
    resetPassword: (form: { email: string }) => void;
    restorePassword: (form: {
      password: string;
      confirm_password: string;
      token: string;
      id: number;
    }) => void;
  };
}

const useAuthStore = create<IAuthStore>((set) => ({
  access_token: null,
  user: null,
  isClient: true,
  isLoading: false,
  isUserLoading: true,
  error: null,
  isSuccess: false,

  actions: {
    fetchUser: async () => {
      authUser()
        .then((res) => {
          const storage = new LocalStorageService();
          const access_token = storage.getItem(ACCESS_TOKEN_KEY);
          const user = res?.data;
          set({ user, access_token });
        })
        .finally(() => set({ isLoading: false, isUserLoading: false }));
    },
    readStartupPopup: () => {
      set((state) => {
        const user = {
          first_name: state.user?.first_name as string || '',
          permissions: state.user?.permissions ?? [],
          is_client: state.user?.is_client ?? true,
          client_startup_popup: true,
          updates_popup_required: state.user?.updates_popup_required ?? true
        };
        return { user };
      });
    },
    readUpdatesPopup: () => {
      set((state) => {
        const user = {
          first_name: state.user?.first_name as string || '',
          permissions: state.user?.permissions ?? [],
          is_client: state.user?.is_client ?? true,
          client_startup_popup: state.user?.client_startup_popup ?? false,
          updates_popup_required: false
        };
        return { user };
      });
    },
    signIn: async (form, rememberMe) => {
      set({ isLoading: true, error: null, isSuccess: false });
      authLogin(form, rememberMe)
        .then((res) => {
          const access_token = res?.data.access_token;
          const user = {
            first_name: res?.data.first_name as string,
            permissions: res?.data.permissions ?? [],
            is_client: res?.data.is_client ?? true,
            client_startup_popup: res?.data.client_startup_popup || false,
            updates_popup_required: res?.data.updates_popup_required || false
          };
          set({ access_token, user });
        })
        .catch((err) => {
          set({ error: err.message });
        })
        .finally(() => set({ isLoading: false }));
    },
    logout: () => {
      authLogout();
      set({ access_token: null, isLoading: false, error: null, user: null });
    },
    resetPassword: (form) => {
      set({ error: null });
      authResetPassword(form)
        .then(() => set({ isSuccess: true }))
        .catch((err) => set({ isSuccess: false, error: err.message }));
    },
    restorePassword: (form) => {
      set({ error: null });
      authRestorePassword(form)
        .then(() => set({ error: null }))
        .catch((err) => set({ error: err.message }));
    },
  },
}));

export const useAuthActions = () => useAuthStore((state) => state.actions);
export const useAuthLoading = () => useAuthStore((state) => state.isLoading);
export const useUserLoading = () => useAuthStore((state) => state.isUserLoading);
export const useAuthError = () => useAuthStore((state) => state.error);
export const useUser = () => useAuthStore((state) => state.user);
export const useIsUser = () => useAuthStore((state) => (state.user ? true : false));
export const usePermissions = () => useAuthStore((state) => state.user?.permissions ?? []);
export const useIsSuccess = () => useAuthStore((state) => state.isSuccess);
export const useAccessToken = () => useAuthStore((state) => state.access_token);
