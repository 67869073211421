import SVG from './SVG.styled';
import { IPropsSVGType } from '#types/svg.types';

export default function ArrowsUpDown({
  color = 'main-orange',
}: IPropsSVGType) {
  return (
    <SVG width="26" height="26" viewBox="0 0 26 26" fill="none" $color={color} xmlns="http://www.w3.org/2000/svg">
      <g id="Used Icon">
        <path d="M6.20289 25.0679L5.42167 25.0616C4.77447 25.0563 4.2541 24.5274 4.25937 23.8802L4.4024 6.30267L2.05873 6.2836C1.01546 6.27511 0.504523 5.00631 1.24638 4.27639L5.18428 0.402054C5.64566 -0.0518239 6.38763 -0.0457862 6.8415 0.415539L10.7158 4.35345C11.4429 5.09254 10.9186 6.3557 9.87097 6.34718L7.5273 6.3281L7.38426 23.9056C7.379 24.5528 6.85009 25.0732 6.20289 25.0679ZM13.8184 1.301L13.6754 18.8785L11.3317 18.8595C10.291 18.851 9.75508 20.1095 10.4868 20.8532L14.3611 24.7911C14.8151 25.2524 15.557 25.2585 16.0183 24.8046L19.9563 20.9303C20.6959 20.2025 20.1906 18.9316 19.1439 18.923L16.8003 18.904L16.9433 1.32643C16.9486 0.679238 16.4282 0.150327 15.781 0.145061L14.9998 0.138704C14.3526 0.133437 13.8237 0.653809 13.8184 1.301Z" />
      </g>
    </SVG>
  );
}
