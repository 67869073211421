export const isMobile = (): boolean => {
    return window.innerWidth < 768
};

export const getDeviceOrientation = (): 'portrait' | 'landscape' => {
  return  window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';
};

export const isMobileLandscapeOrientation = (): boolean => {
  const orientation = getDeviceOrientation();
  return orientation === 'landscape' && window.innerWidth < 900 && window.innerHeight < 500;
};

export const isFiltersDesktopMode = (): boolean => {
  return window.innerWidth > 1265
};

export const delay = (time: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, time));
}

export const getAlphabet = () => {
  const alphabet = [
    'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L',
    'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'
  ];

  return alphabet;
};

export const truncateText = (text: string, max_length: number): { text: string, origin_text: string, truncated: boolean} => {
    if (!text) { return { text: '', origin_text: '', truncated: false }; }
    if (text.length > max_length) {
      return { 
        origin_text: text,
        text: text.substring(0, max_length) + '...',
        truncated: true
      };
   }
   return { text: text, origin_text: text, truncated: false };
}

export const hideTooltipById = (tooltipId: string, additionalClasses?: string) => {
  const tooltip = document.getElementById(tooltipId) as HTMLElement;
  hideTooltip(tooltip, additionalClasses);
}

export const hideTooltip = (tooltip: HTMLElement, additionalClasses?: string) => {
  tooltip.style.visibility = 'hidden';
  tooltip.style.display = 'none';

  setTooltipClass(tooltip, `Tooltip-Tip ${additionalClasses || ''} right`);
}

export const showTooltipById = (tooltipId: string) => {
  const tooltip = getTooltipById(tooltipId);
  showTooltip(tooltip);
}

export const showTooltip = (tooltip: HTMLElement) => {
  tooltip.style.visibility = 'visible';
}

export const getTooltipById = (tooltipId: string) => {
  const tooltip = document.getElementById(tooltipId) as HTMLElement;
  return tooltip;
}

export const setTooltipClass = (tooltip: HTMLElement, className: string) => {
  tooltip.className = className;
}

export const setTooltipPosition = (tooltipId: string, defaultPosition: string, additionalClasses?: string): HTMLElement | null  => {
  let position = defaultPosition;

  const tableContainer = document.getElementById('car-catalog-table-container');

  if (!tableContainer) {
    return null;
  }
  const tableContainerPosition = tableContainer.getBoundingClientRect();
  
  const tooltip = getTooltipById(tooltipId);

  if (!tooltip) {
    return null;
  }

  tooltip.style.display = 'block';
  setTooltipClass(tooltip, `Tooltip-Tip ${additionalClasses || ''} ${position}`);

  const startVisibleArea = tableContainerPosition.top + 50;
  const endVisibleArea = tableContainerPosition.bottom - 50;

  const tooltipPosition = tooltip.getBoundingClientRect();

  const isTooltipTopInvisible = tooltipPosition.top < startVisibleArea;
  const isTooltipBottomInvisible = tooltipPosition.bottom > endVisibleArea;
  const isTooltipFullyVisible = !isTooltipTopInvisible && !isTooltipBottomInvisible;

  if (isTooltipTopInvisible) {
    position = 'bottom';
  }

  if (isTooltipBottomInvisible) {
    position = 'top';
  }

  if (isTooltipFullyVisible) {
    position = defaultPosition;
  }

  setTooltipClass(tooltip, `Tooltip-Tip ${additionalClasses || ''} ${position}`);

  return tooltip;
}

export const setCountClipsMenuPosition = (menuId: string, defaultHeight: number) => {
  const menu = document.getElementById(menuId) as HTMLElement;
  const bottomGap = 50;
  const topGap = 100;
  const minMenuHeight = 100;

  if (!menu) {
    return;
  }

  const tableContainer = document.getElementById('car-catalog-table-container');

  if (!tableContainer) {
    return;
  }

  // set initial style
  menu.style.top = '100%';
  menu.style.bottom = 'unset';
  menu.style.height = `${defaultHeight}px`;

  const tableContainerPosition = tableContainer.getBoundingClientRect();
  const startVisibleArea = tableContainerPosition.top;
  const endVisibleArea = tableContainerPosition.bottom - bottomGap;

  const menuPosition = menu.getBoundingClientRect();

  const isMenuTopInvisible = menuPosition.top < startVisibleArea;
  const isMenuBottomInvisible = menuPosition.bottom > endVisibleArea;
  const isTooltipFullyVisible = !isMenuTopInvisible && !isMenuBottomInvisible;
          
  if (isMenuBottomInvisible) {
      const heightToShow = menuPosition.height - (menuPosition.bottom - endVisibleArea);

      if (heightToShow > minMenuHeight) {
          menu.style.height = `${heightToShow}px`;
      } else {
          const height = menuPosition.top - startVisibleArea - topGap;
          
          menu.style.top = 'unset';
          menu.style.bottom = '100%';
          menu.style.height = height < defaultHeight ? `${height}px` : `${defaultHeight}px`;
      }
  }
}

export const formatDate = (date: Date | null, format: 'yyyy-mm-dd' | 'dd.mm.yyyy'): string => {
  if (!date) {
    return '';
  }

  let day = String(date.getDate());
  let month = String(date.getMonth() + 1);
  let year = String(date.getFullYear());

  if (day.length === 1) {
    day = `0${day}`;
  }

  if (month.length === 1) {
    month = `0${month}`;
  }

  if (format === 'dd.mm.yyyy') {
    return `${day}.${month}.${year}`;
  }

  if (format === 'yyyy-mm-dd') {
    return `${year}-${month}-${day}`;
  }

  return `${day}.${month}.${year}`;
}

export const getYearsAsDigits = (years: string): number[] => {
  if (!years) {
    return [];
  }
  let yearsWithoutSymbols = years.replace('(', '').replace(')', '').replace('-', '');
  let splittedYears = yearsWithoutSymbols.split(' ');
  let result = splittedYears.filter(x => x).map(x => +x);

  return result;
}

export const groupItemsBy = (list: any[], by: string, field: string) => {
  const result: any = {};
  list.forEach((item) => {
    const key = item[by];
    const value: any = item[field];

    if (result[key] && !result[key].includes(value)) {
      result[key] = result[key].concat(value);
    }

    if (!result[key]) {
      result[key] = [].concat(value);
    }
  });
  return result;
}