export const ACCESS_TOKEN_KEY = 'access_token';
export class LocalStorageService {
    getJsonItem(key: string) {
        const value = localStorage.getItem(key);
        if (value) {
            return JSON.parse(value);
        }
        return {};
        
    }

    getItem(key: string) {
        return localStorage.getItem(key);        
    }

    setJsonItem(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    setItem(key: string, value: any) {
        localStorage.setItem(key, value);
    }

    removeItem(key: string) {
        localStorage.removeItem(key);
    }
}
